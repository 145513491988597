import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,

  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE,
  ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR,

  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,

  DELETE_PROGRAM_BROCHURE_SECTION_REQUEST,
  DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS,
  DELETE_PROGRAM_BROCHURE_SECTION_FAILURE,
  DELETE_PROGRAM_BROCHURE_SECTION_CLEAR,
} from '../actions/types';

const initialState = {
  programCreate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programDeleteDraft: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  programGet: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programModify: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  batchProgramBrochureSection: {
    batchData: null,
    batchError: null,
    batchErrorMsg: '',
    batchLoading: false,
    batchSuccess: false,
  },
  programBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  deleteProgramBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  batchDeleteProgramBrochureSection: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_PROGRAM_CLEAR:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_REQUEST:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case CREATE_NEW_PROGRAM_SUCCESS:
      return {
        ...state,
        programCreate: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_FAILURE:
      return {
        ...state,
        programCreate: {
          data: null,
          error: true,
          errorMsg: 'Error creating new program.',
          loading: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_REQUEST:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_SUCCESS:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: false,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case DELETE_DRAFT_PROGRAM_FAILURE:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: true,
          errorMsg: 'There was a problem deleting...',
          loading: false,
          success: false,
        },
      };
    case GET_PROGRAM_REQUEST:
      return {
        ...state,
        programGet: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case GET_PROGRAM_SUCCESS:
      return {
        ...state,
        programGet: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case GET_PROGRAM_FAILURE:
      return {
        ...state,
        programGet: {
          data: null,
          error: true,
          errorMsg: 'There was and error loading....',
          loading: false,
        },
      };
    case MODIFY_PROGRAM_CLEAR:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case MODIFY_PROGRAM_REQUEST:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case MODIFY_PROGRAM_SUCCESS:
      return {
        ...state,
        programModify: {
          data: action.payload.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case MODIFY_PROGRAM_FAILURE:
      return {
        ...state,
        programModify: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };

    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: false,
          batchSuccess: false,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: true,
          batchSuccess: false,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: action?.payload?.data,
          batchError: null,
          batchErrorMsg: '',
          batchLoading: false,
          batchSuccess: true,
        },
      };
    case BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        batchProgramBrochureSection: {
          batchData: null,
          batchError: true,
          batchErrorMsg: action?.payload?.data,
          batchLoading: false,
          batchSuccess: false,
        },
      };   
    
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR:
        return {
          ...state,
          programBrochureSection: {
            data: null,
            error: null,
            errorMsg: '',
            loading: false,
            success: false,
          },
        };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        programBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        programBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE:
        return {
          ...state,
          programBrochureSection: {
            data: null,
            error: true,
            errorMsg: '',
            loading: false,
            success: false,
          },
        };

    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };        
    case BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        batchDeleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      }; 
    case DELETE_PROGRAM_BROCHURE_SECTION_REQUEST:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: action?.payload?.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case DELETE_PROGRAM_BROCHURE_SECTION_FAILURE:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };      
    case DELETE_PROGRAM_BROCHURE_SECTION_CLEAR:
      return {
        ...state,
        deleteProgramBrochureSection: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };   
    default:
      return state;
  }
}
